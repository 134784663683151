<template>
  <div>
    <h4>Orders Stats</h4>
    <div class="background">
      <div class="row">
        <div class="col-6">
          <categories v-model="category" @selected="change($event)" />
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-6">
          <h6>{{category}}</h6>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col">
          <div v-if="!chartReady">
            <div class="spinner-border spinner-border-sm ms-2" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
          <apexchart v-if="chartReady" type="line" height="350" :options="chartOptions" :series="series"></apexchart>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "../http.js";
import { formatValue } from "./../formatters";
import { forEach } from "lodash";
import VueApexCharts from "vue-apexcharts";
import Categories from "./widgets/helpers/Categories";

export default {
  data() {
    return {
      chartReady: false,
      category: null,
      chartOptions: null,
      series: null,
    };
  },
  components: { apexchart: VueApexCharts, Categories },
  mounted() {
    this.getChartData();
  },
  methods: {
    change(event) {
      this.chartReady = false;
      this.getChartData(event.id);
    },
    formatValue,
    getChartData(category = 1) {
      axios
        .get("/api/admin/dashboard/stats/order-items/category/" + category)
        .then((response) => {
          this.chartReady = true;

          if (response.data) {
            let element = response.data;
            let data = [];
            let orders = [];
            let i = 1;
            let maxvalue = 0;
            forEach(element, (numberoforders) => {
              orders.push(i);
              i = i + 1;
              data.push(numberoforders);
              maxvalue = Math.max(maxvalue, numberoforders);
            });

            let series = [
              {
                name: this.category ? this.category : "All",
                data: data,
              },
            ];

            this.chartOptions = {
              chart: {
                height: 350,
                type: "line",
                dropShadow: {
                  enabled: true,
                  color: "#000",
                  top: 18,
                  left: 7,
                  blur: 10,
                  opacity: 0.2,
                },
                toolbar: {
                  show: true,
                  offsetX: 0,
                  offsetY: 0,
                  tools: {
                    download: true,
                    selection: true,
                    zoom: true,
                    zoomin: true,
                    zoomout: true,
                    pan: true,
                    reset:
                      true | '<img src="/static/icons/reset.png" width="20">',
                    customIcons: [],
                  },
                  export: {
                    csv: {
                      filename: undefined,
                      columnDelimiter: ",",
                      headerCategory: this.category ? this.category : "All",
                      headerValue: "value",
                      dateFormatter(timestamp) {
                        return new Date(timestamp).toDateString();
                      },
                    },
                    svg: {
                      filename: undefined,
                    },
                    png: {
                      filename: undefined,
                    },
                  },
                  autoSelected: "zoom",
                },
              },
              colors: ["#77B6EA", "#545454"],
              dataLabels: {
                enabled: false,
              },
              stroke: {
                curve: "smooth",
              },
              title: {
                text: "category",
                align: "left",
              },

              markers: {
                size: 1,
              },
              xaxis: {
                categories: [],
                title: {
                  text: "items per order",
                },
              },
              yaxis: {
                title: {
                  text: "orders",
                },
                logarithmic: false,
                min: 0,
                // max: maxvalue + 1,
              },
              legend: {
                position: "top",
                horizontalAlign: "right",
                floating: true,
                offsetY: -25,
                offsetX: -5,
              },
            };

            this.series = series;
          }
          this.chartReady = true;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.background {
  background: white;
  padding: 20px;
}
tbody tr {
  &:hover {
    background: #fafafa;
  }
}
</style>