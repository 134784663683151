<template>
  <div class="form-group mt-2" v-if="ready">
    <label class="block text-sm flex items-center" :for="'input'+id">{{ label }}</label>
    <select v-model="selected" v-if="ready" class="form-select" :id="'input'+id">
      <option v-for="category in categories" :key="category.id" :value="category.id">{{ category.name }}</option>
    </select>
  </div>
</template>
<script>
import axios from "./../../../http.js";

export default {
  props: ["value", "label"],
  data: function () {
    return {
      // check if we are ready, otherwise the wrong category will be selected
      ready: false,
      categories: {},
      selected: this.getCategory(this.value),
    };
  },
  computed: {
    id() {
      return this._uid;
    },
  },
  methods: {
    getCategory(name) {
      if (!this.categories) {
        return null;
      }
      let foundCategory = this.categories.find((category) => {
        return category.name == name;
      });
      return foundCategory ? foundCategory.id : null;
    },
  },
  watch: {
    selected(newVal) {
      console.log(newVal)
      let selected = this.categories.find(c => {
        return c.id == newVal
      })
      console.log(selected)
      if (selected) {
        this.$emit("input", selected.name);
        this.$emit("selected", selected);
      }
    },
    value() {
      this.selected = this.getCategory(this.value);
    },
  },
  mounted() {
    axios
      .get("/api/categories?flat=true")
      .then((response) => {
        this.$nextTick(() => {
          this.categories = response.data.data;
          this.ready = true;
          this.selected = this.getCategory(this.value);
        });
      })
      .finally(() => {});
  },
};
</script>

<style lang="scss">
.header {
  background: #2c3e50;
  color: white;
  text-align: center;

  strong {
    font-family: "Ubuntu Mono", monospace;
    font-weight: bold;
  }
}
.save {
  float: right;
}
.form-group .row {
  margin-top: 10px;
}
.form-preview-image {
  width: 250px;
  margin: 10px 0px;
}
.banner-content {
  color: white;
  background: black;
}
</style>